@import url(https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;500;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  background-color: #282c34;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ComingSoon_wrapper__mg0_9{height:100vh;display:flex;flex-direction:column;align-content:center;justify-content:center;text-align:center;margin:auto auto auto;color:white;font-family:"Ubuntu", sans-serif}.ComingSoon_wrapper__mg0_9 h1{font-size:24px}@media (min-width: 576px){.ComingSoon_wrapper__mg0_9 h1{font-size:26px}}@media (min-width: 768px){.ComingSoon_wrapper__mg0_9 h1{font-size:32px}}@media (min-width: 992px){.ComingSoon_wrapper__mg0_9 h1{font-size:40px}}@media (min-width: 1200px){.ComingSoon_wrapper__mg0_9 h1{font-size:52px}}.ComingSoon_wrapper__mg0_9 h2{font-size:18px}@media (min-width: 576px){.ComingSoon_wrapper__mg0_9 h2{font-size:24px}}@media (min-width: 768px){.ComingSoon_wrapper__mg0_9 h2{font-size:26px}}@media (min-width: 992px){.ComingSoon_wrapper__mg0_9 h2{font-size:32px}}@media (min-width: 1200px){.ComingSoon_wrapper__mg0_9 h2{font-size:40px}}.ComingSoon_wrapper__mg0_9 .ComingSoon_githubLink__22LUk{font-size:26px;color:white}.ComingSoon_wrapper__mg0_9 .ComingSoon_githubLink__22LUk .ComingSoon_githubIcon__148YZ{padding:10px;font-size:50px}

.Logo_logo__3IG6m{width:250px;display:block;margin-left:auto;margin-right:auto}@media (min-width: 576px){.Logo_logo__3IG6m{width:350px}}@media (min-width: 768px){.Logo_logo__3IG6m{width:400px}}@media (min-width: 992px){.Logo_logo__3IG6m{width:450px}}@media (min-width: 1200px){.Logo_logo__3IG6m{width:500px}}


@mixin BREAKPOINT-SM {
  // Small tablets and large smartphones (landscape view)
  @media (min-width: 576px) {
    @content;
  }
}

@mixin BREAKPOINT_M {
  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    @content;
  }
}

@mixin BREAKPOINT_L {
  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    @content;
  }
}

@mixin BREAKPOINT_XL {
  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) {
    @content;
  }
}
